<template>
    <div class="slider">
        <div class="slider__content">
            <vueper-slides
                class="no-shadow"
                :visible-slides="3"
                :slide-multiple="3"
                :gap="3"
                :slide-ratio="1 / 4"
                :dragging-distance="200"
                :breakpoints="{ 
                    1240: { 
                        visibleSlides: 2, slideMultiple: 2 
                    }, 
                    768: {
                        slideRatio: 1,
                        visibleSlides: 1, slideMultiple: 1 
                    } 
                }"
            >
                <template #arrow-left>
                    <div class="arrow arrow-prev"></div>
                </template>

                <template #arrow-right>
                    <div class="arrow arrow-next"></div>
                </template>
                <vueper-slide 
                    v-for="photo in photos" 
                    :key="photo.id" 
                    :image="photo.url"
                    @click="openModal({'src': `${photo.url}`, 'alt': photo.name})"
                    />
            </vueper-slides>
        </div>
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import { mapActions } from 'vuex';
    
    export default {
        props: ["photos"],
        components: {
            VueperSlides, 
            VueperSlide,
        },
        methods: {
            next() {
                this.$refs.slick.next();
            },
            prev() {
                this.$refs.slick.prev();
            },
            reInit() {
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },
            ...mapActions(["openModal"]),
        }
    }
</script>