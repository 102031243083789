<template>
  <div id="main">
    <section class="section section-banner" v-if="content.banner">
        <div class="banner__content">
          <h1 
            class="banner__title title"
            v-if="content.banner.title"
            >{{ content.banner.title }}</h1>
          <span 
            class="banner__subtitle"
            v-if="content.banner.subtitle"
            >{{ content.banner.subtitle }}</span>
        </div>
          <div class="banner__img-wrap"
          v-if="content.banner.image"
          :style="[content.banner.image.url ? { 'background-image': 'url(' + content.banner.image.url + ')' } : '']"
          ></div>
    </section>
    <section class="section section-depiction" v-if="content.description">
      <div class="container">
        <div class="section__content">
          <div class="depiction__text content" v-if="content.description.description" v-html="content.description.description"></div>
          <div class="section__img-wrap section__img-left"></div>
        </div>
      </div>
    </section>
    <section class="section section-mission" id="mission" v-if="content.mission.length > 0">
      <div class="container">
        <div class="section__content">
          <h4 class="section__title title">Цели фонда</h4>
          <div class="missions">
            <div 
              class="mission"
              v-for="mission in content.mission"
              :key="mission.id"
              :style="[mission.image.url ? { 'background-image': 'url(' + mission.image.url + ')' } : '']"
              >
              <div class="mission__text">{{ mission.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-helping" id="helping" v-if="content.Whoos_helping && content.type_helping">
      <div class="container">
        <div class="section__content">
          <h4 class="section__title title">Кому помогаем</h4>
          <span class="section__subtitle subtitle line">и виды помощи</span>
          <div class="helping__wrap content" v-if="content.Whoos_helping" v-html="content.Whoos_helping.text"></div>
          <div class="helping__wrap content" v-if="content.type_helping" v-html="content.type_helping.text"></div>
        </div>
      </div>
    </section>
    <section class="section section-gallery" id="gallery" v-if="content.photo_report">
      <div class="container">
        <h4 class="section__title title">Фотоотчет</h4>
        <Slider :photos="content.photo_report.photo" />
      </div>
    </section>
    <section class="section section-information" v-if="content.news.length > 0">
      <div class="container">
        <h4 class="section__title title">Новости</h4>
        <div class="section__content">
          <News :news="content.news.slice(0,3)" isMain='true' />
        </div>
      </div>
    </section>
    <section class="section section-activity" id="activity" v-if="content.about">
      <div class="container">
        <div class="section__content">
          <h3 
            class="section__title title" 
            v-if="content.about.title"
            >{{ content.about.title }}
          </h3>
          <div class="activity__text content" v-if="content.about.text" v-html="content.about.text"></div>
          <div class="section__img-wrap section__img-left"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import News from '@/components/news/News.vue';
import Slider from '@/components/slider/Slider.vue';
import { mapGetters } from 'vuex';
export default {
  name: "Main",
  components: {
     News,
     Slider,
  },
  computed: {
    ...mapGetters({'content': 'getContent'}),
  }
};
</script>
